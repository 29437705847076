import React, { useState } from 'react';
import {
  Box,
  Stack,
  HStack,
  Text,
  VStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';
// import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
// import PayPalSubscribeButton from "./paypal"
import { FaCheckCircle } from 'react-icons/fa';


interface Props {
  children: React.ReactNode;
}

interface PlanDetails {
  type: string;
  amount: number;
  currency: string;
}

// const steps = [
//   { title: 'First', description: 'Contact Info' },
//   { title: 'Second', description: 'Date & Time' },
//   { title: 'Third', description: 'Select Rooms' },
// ]

// interface MessageProps {
//   content: string;
// }
// function Message({ content }: MessageProps) {
//   return <p>{content}</p>;
// }

function PriceWrapper(props: Props) {
  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: 'center', lg: 'flex-start' }}
      borderColor={useColorModeValue('gray.200', 'gray.500')}
      borderRadius="xl"
    >
      {props.children}
    </Box>
  );
}

export default function Pricing() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedPlan, setSelectedPlan] = useState<PlanDetails | null>(null);
  // const { activeStep, setActiveStep } = useSteps({
  //   index: 1,
  //   count: steps.length,
  // })

  const handleStartTrial = (type: string, amount: number, currency: string) => {
    setSelectedPlan({ type, amount, currency });
    onOpen();
  };
  // const initialOptions = {
  //   "client-id": "test",
  //   "enable-funding": "paylater,venmo,card",
  //   "disable-funding": "",
  //   "data-sdk-integration-source": "integrationbuilder_sc",
  // };

  const [message, setMessage] = useState("");

  const handlePayPalRedirectOne = () => {
    if(selectedPlan.type === "Free") {
 // Redirect the user to the PayPal Free Plan payment page 
 window.location.href = "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=CYRKSG7S38M4S";
    }
    if(selectedPlan.type === "Pro") {
      // Redirect the user to the PayPal Free Plan payment page 
      window.location.href = "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=FCB8SZXF7AE4N";
         }
         if(selectedPlan.type === "Enterprise") {
          // Redirect the user to the PayPal Free Plan payment page 
          window.location.href = "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=K5XCXGBNRX72C";
             }
   
};
//   const handlePayPalRedirectTwo = () => {

//         // Redirect the user to the PayPal Pro Plan payment page 
//         window.location.href = "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=FCB8SZXF7AE4N"
       
//   };

//   const handlePayPalRedirectThree = () => {

//   window.location.href = "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=K5XCXGBNRX72C"
// };

  return (
    <Box py={12}>
      <VStack spacing={2} textAlign="center">
        <Text fontSize="6xl" textAlign="center" fontWeight="bold">
          Plans that fit your need
        </Text>
        <Text fontSize="lg" color={'gray.500'}>
          We offer competitive pricing for businesses of all sizes and needs.
        </Text>
      </VStack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={10}
      >
        {/* Plans */}
        <PriceWrapper>
          <Plan
            type="Free"
            amount={0}
            currency="$"
            features={['1 user included', 'API Access', 'Up to 100 requests per month', 'Email support']}
            onTrialClick={handleStartTrial}
          />
        </PriceWrapper>
        <PriceWrapper>
          <Plan
            type="Pro"
            amount={50}
            currency="$"
            features={['Up to 5 users included', 'API Access', 'Up to 500 requests per month', '24/7 Phone & Email support']}
            onTrialClick={handleStartTrial}
          />
        </PriceWrapper>
        <PriceWrapper>
          <Plan
            type="Enterprise"
            amount={100}
            currency="$"
            features={['Up to 10 users included', 'API Access', 'Unlimited requests per month', '24/7 Phone & Email support']}
            onTrialClick={handleStartTrial}
          />
        </PriceWrapper>
      </Stack>

      {/* Modal for displaying selected plan details */}
      <Modal isOpen={isOpen} onClose={onClose}>
      
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select your Plan</ModalHeader>
          <ModalCloseButton />
          <ModalBody>

            {selectedPlan && (
              <Text>
                You have selected the <b>{selectedPlan.type}</b> plan priced at {selectedPlan.currency}{selectedPlan.amount}/month (VAT Incl.). Please select your payment option. We will charge the 0.01 USD to verify payment method, which will be refunded.
              </Text>
              
              
            )}
           
<Button
  size='md'
  height='48px'
  width='200px'
  border='2px'
  borderColor='green.500'
  onClick={handlePayPalRedirectOne}
>
  Paypal
</Button>
            <Button
  size='md'
  height='48px'
  width='200px'
  border='2px'
  borderColor='green.500'
>
  Airtm
</Button>
<Button
  size='md'
  height='48px'
  width='200px'
  border='2px'
  borderColor='green.500'
>
  USDC
</Button>
<Button
  size='md'
  height='48px'
  width='200px'
  border='2px'
  borderColor='green.500'
>
  xBull
</Button>
<Button
  size='md'
  height='48px'
  width='200px'
  border='2px'
  borderColor='green.500'
>
  Freighter
</Button>
<Button
  size='md'
  height='48px'
  width='200px'
  border='2px'
  borderColor='green.500'
>
  Albedo
</Button>
<Button
  size='md'
  height='48px'
  width='200px'
  border='2px'
  borderColor='green.500'
>
Whalestack
</Button>
<Button
  size='md'
  height='48px'
  width='200px'
  border='2px'
  borderColor='green.500'
>
  Beans
</Button>
By continuing, you confirm that you meet the age requirement as defined by your local laws and agree to adhere to Mozart’s <a href="https://www.mozartpay.com/terms"> <b>Terms</b>  </a>. By proceeding with the payment, you request immediate execution of the service and acknowledge that this waives your right to withdraw from the agreement. Please review our <a href="https://www.mozartpay.com/privacy"><b>Privacy Policy</b></a> .
          </ModalBody>
         
          <ModalFooter>
           
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

function Plan({ type, amount, currency, features, onTrialClick }: PlanDetails & { features: string[]; onTrialClick: (type: string, amount: number, currency: string) => void }) {
  return (
    <>
      <Box py={4} px={12}>
        <Text fontWeight="500" fontSize="2xl">
          {type}
        </Text>
        <HStack justifyContent="center">
          <Text fontSize="3xl" fontWeight="600">
            {currency}
          </Text>
          <Text fontSize="5xl" fontWeight="900">
            {amount}
          </Text>
          <Text fontSize="3xl" color="gray.500">
            /month
          </Text>
        </HStack>
      </Box>
      <VStack bg={useColorModeValue('gray.50', 'gray.700')} py={4} borderBottomRadius="xl">
        <List spacing={3} textAlign="start" px={12}>
          {features.map(feature => (
            <ListItem key={feature}>
              <ListIcon as={FaCheckCircle} color="green.500" />
              {feature}
            </ListItem>
          ))}
        </List>
        <Box w="80%" pt={7}>
          <Button w="full" colorScheme="red" onClick={() => onTrialClick(type, amount, currency)}>
            Start trial
          </Button>
        </Box>
      </VStack>
    </>
  );
}
