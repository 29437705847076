import React, { useState, ChangeEvent } from 'react';
import { NavLink } from "react-router-dom";
import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/mozart.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

interface UserData {
    email: string;
    name: string;
}

function SignUp() {
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const textColorBrand = useColorModeValue("brand.500", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    
    const [show, setShow] = useState(false);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [fullname, setName] = useState<string>('');
    const [number, setnumber] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleClick = () => setShow(!show);

    const handleSignUp = async () => {
        if (password.length < 8) {
            setErrorMessage('Password must be at least 8 characters.');
            return;
        }
        
        try {
            const response = await fetch('https://mozart-api-21ea5fd801a8.herokuapp.com/api/signup/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({ email, password, fullname, number }),
            });

            if (response.ok) {
                const responseData = await response.json();
                const userData: UserData = responseData.user;

                localStorage.setItem('user', JSON.stringify(userData));
                window.location.href = '/verifyAccount';
            } else {
                const data = await response.json();
                setErrorMessage(data.message || 'Signup failed');
            }
        } catch (error) {
            setErrorMessage('An error occurred during signup. Please try again.');
        }
    };

    const handleChange = (newValue: string) => {
        setnumber(newValue);
    };

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w='100%'
                mx={{ base: "auto", lg: "0px" }}
                me='auto'
                h='100%'
                alignItems='start'
                justifyContent='center'
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}
                mt={{ base: "40px", md: "14vh" }}
                flexDirection='column'>
                <Box me='auto'>
                    <Heading color={textColor} fontSize='36px' mb='10px'>
                        Sign Up
                    </Heading>
                    <Text
                        mb='36px'
                        ms='4px'
                        color={textColorSecondary}
                        fontWeight='400'
                        fontSize='md'>
                        Enter your name, email, and password to sign up!
                    </Text>
                </Box>
                <Flex
                    zIndex='2'
                    direction='column'
                    w={{ base: "100%", md: "420px" }}
                    maxW='100%'
                    background='transparent'
                    borderRadius='15px'
                    mx={{ base: "auto", lg: "unset" }}
                    me='auto'
                    mb={{ base: "20px", md: "auto" }}>
                    <FormControl>
                        <FormLabel fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
                            Full Name<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <Input
                            isRequired
                            fontSize='sm'
                            type='text'
                            placeholder='John Foulen'
                            mb='24px'
                            value={fullname}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                        />
                        <FormLabel fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
                            Email<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <Input
                            isRequired
                            fontSize='sm'
                            type='email'
                            placeholder='mail@example.com'
                            mb='24px'
                            value={email}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                        />
                        <FormLabel fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
                            Password<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <InputGroup size='md'>
                            <Input
                                isRequired
                                fontSize='sm'
                                placeholder='Min. 8 characters'
                                mb='24px'
                                type={show ? "text" : "password"}
                                value={password}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                            />
                            <InputRightElement>
                                <Icon
                                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                    onClick={handleClick}
                                />
                            </InputRightElement>
                        </InputGroup>
                        <PhoneInput
                            placeholder="Enter phone number"
                            value={number}
                            onChange={handleChange}
                        />
                        <br />
                        {errorMessage && (
                            <Text color="red.500" fontSize="sm" mb="16px">
                                {errorMessage}
                            </Text>
                        )}
                        <Button onClick={handleSignUp}>
                            Sign Up
                        </Button>
                    </FormControl>
                </Flex>
            </Flex>
        </DefaultAuth>
    );
}

export default SignUp;
