import React, { useState, useEffect } from 'react';
import {
  Box, Text, SimpleGrid, useColorModeValue, Button, Flex, Image,
  InputGroup, InputLeftElement, Input, Select, useDisclosure, Modal,
  ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody,
  ModalFooter, Spinner, useToast
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import axios from 'axios';

import USDC from 'assets/img/dashboards/USDC.png';
import EURC from 'assets/img/dashboards/EURC.png';
import COP from 'assets/img/dashboards/COP.png';
import BTC from 'assets/img/dashboards/BTC.png';
import ETH from 'assets/img/dashboards/ETH.png';
import XLM from 'assets/img/dashboards/XLM.png';

export default function Withdraw() {
  const [amount, setAmount] = useState<string>(''); 
  const [currency, setCurrency] = useState<string>(''); 
  const [balances, setBalances] = useState<{ [key: string]: string }>({}); // Store balances for all currencies
  const [xlmAddress, setXlmAddress] = useState<string>(''); 
  const [loading, setLoading] = useState<boolean>(false); 
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isConfirmationOpen,
    onOpen: onConfirmationOpen,
    onClose: onConfirmationClose,
  } = useDisclosure();
  const toast = useToast(); 
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const currencySymbols: { [key: string]: string } = {
    USD: '$',
    EUR: '€',
    COP: 'COL$',
    BTC: '₿',
    ETH: 'Ξ',
    XLM: '*',
  };

  // Fetch all balances from the API
  const fetchBalances = async () => {
    try {
      const userData = localStorage.getItem('user');
      const { email } = userData ? JSON.parse(userData) : { email: '' };

      if (email) {
        const response = await axios.get(`https://mozart-api-21ea5fd801a8.herokuapp.com/api/balance?email=${encodeURIComponent(email)}`);
        
        // Assuming the API returns an object with balances for each currency
        const apiBalances = response.data.balances || [];
        
        const formattedBalances: { [key: string]: string } = {};
        apiBalances.forEach((balance: any) => {
          const currency = balance.asset_code || 'XLM'; // If no asset_code, it's XLM
          formattedBalances[currency] = balance.balance;
        });
        
        setBalances(formattedBalances); // Update state with all balances
      } else {
        console.error('User email not found in local storage.');
      }
    } catch (error) {
      console.error('Error fetching balances:', error);
    }
  };

  useEffect(() => {
    fetchBalances(); // Fetch balances when the component loads
  }, []);

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setAmount(event.target.value);
  };

  const handleCurrencyChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const selectedCurrency = event.target.value;
    setCurrency(selectedCurrency);
  };

  const handleWithdraw = async () => {
    onOpen(); // Open modal to input XLM address
  };

  const submitWithdrawRequest = async () => {
    setLoading(true);
    try {
      const userData = localStorage.getItem('user');
      const { email } = userData ? JSON.parse(userData) : { email: '' };

      await axios.post(
        'https://mozart-api-21ea5fd801a8.herokuapp.com/api/withdraw',
        {
          amount: amount,
          currency: currency,
          xlmAddress: xlmAddress,
          email: email,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        }
      );

      // Upon successful withdrawal, show confirmation modal
      onConfirmationOpen();
    } catch (error) {
      console.error('Error creating withdrawal request:', error);
      toast({
        title: "Withdrawal Failed",
        description: "An error occurred while processing your withdrawal.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false); 
      onClose(); 
    }
  };

  const handleConfirmationClose = () => {
    onConfirmationClose(); // Close the confirmation modal
    window.location.reload(); // Refresh the page
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Text mt='45px' mb='36px' color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
        Please select the currency to withdraw:
      </Text>
      <Select placeholder="Select currency" value={currency} onChange={handleCurrencyChange} mb="24px" maxWidth="300px">
        {Object.keys(balances).map((key) => (
          <option key={key} value={key}>
            {key}
          </option>
        ))}
      </Select>

      <Text color={textColor} fontSize='lg' ms='24px' fontWeight='600'>
        Current {currency} Balance: {currencySymbols[currency]}{balances[currency] || '0.00'}
      </Text>

      <Text mt='45px' mb='36px' color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
        Please enter the amount to withdraw:
      </Text>
      <InputGroup>
        <InputLeftElement pointerEvents='none' color='gray.300' fontSize='1.2em'>
          {currencySymbols[currency] || ''}
        </InputLeftElement>
        <Input placeholder='Enter amount' value={amount} onChange={handleAmountChange} />
      </InputGroup>

      <Text mt='45px' mb='36px' color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
        Please choose your withdrawal method:
      </Text>
      <SimpleGrid mb='20px' columns={{ sm: 2, md: 5 }} spacing={{ base: '20px', xl: '20px' }}>
        {currency === 'XLM' && (
          <Card p='20px'>
            <Flex direction={{ base: 'column' }} justify='center'>
              <Box mb={{ base: '20px', '2xl': '20px' }} position='relative'>
                <Image src={XLM} w={{ base: '100%', '3xl': '100%' }} h={{ base: '100%', '3xl': '100%' }} borderRadius='20px' />
              </Box>
              <Flex flexDirection='column' justify='space-between' h='100%'>
                <Flex justify='space-between' direction={{ base: 'row', md: 'column', lg: 'row', xl: 'column', '2xl': 'row' }} mb='auto'>
                  <Flex direction='column'>
                    <Text color={textColor} fontSize={{ base: 'xl', md: 'lg', lg: 'lg', xl: 'lg', '2xl': 'md', '3xl': 'lg' }} mb='5px' fontWeight='bold'>
                      Stellar (XLM)
                    </Text>
                    <Text color='secondaryGray.600' fontSize={{ base: 'sm' }} fontWeight='400'>
                      Withdraw XLM to your Stellar wallet
                    </Text>
                  </Flex>
                </Flex>
                <Flex align='center' justify='center' mt='25px'>
                  <Button
                    variant='darkBrand'
                    color='white'
                    fontSize='sm'
                    fontWeight='500'
                    borderRadius='70px'
                    px='24px'
                    py='5px'
                    onClick={handleWithdraw}
                    isDisabled={!amount || currency !== 'XLM'}
                  >
                    Withdraw
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Card>
        )}
      </SimpleGrid>

      {/* Modal for XLM Address */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enter XLM Address</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Stellar XLM Address"
              value={xlmAddress}
              onChange={(e) => setXlmAddress(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={submitWithdrawRequest}
              isDisabled={loading || !xlmAddress}
            >
              {loading ? <Spinner size="sm" /> : 'Confirm Withdraw'}
            </Button>
            <Button variant="ghost" onClick={onClose} isDisabled={loading}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Confirmation Modal after successful withdrawal */}
      <Modal isOpen={isConfirmationOpen} onClose={handleConfirmationClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Withdrawal Successful</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Your withdrawal of {amount} {currency} has been processed successfully.</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleConfirmationClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
