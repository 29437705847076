import { Box, Grid, Text, Button, Input, IconButton, Tooltip } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { CopyIcon } from '@chakra-ui/icons';
import Banner from 'views/admin/profile/components/Banner';
import Notifications from 'views/admin/profile/components/Notifications';
import Upload from 'views/admin/profile/components/Upload';
import banner from 'assets/img/auth/banner.png';
import avatar from 'assets/img/avatars/avatar4.png';
import axios from 'axios';

export default function Overview() {
  const [userName, setUserName] = useState<string>('');
  const [userImage, setUserImage] = useState<string | null>(null); 
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [publicKey, setPublicKey] = useState<string | null>(null);
  const [privateKey, setPrivateKey] = useState<string | null>(null);
  const [isPrivateKeyBlurred, setIsPrivateKeyBlurred] = useState<boolean>(true);
  const [loadingPrivateKey, setLoadingPrivateKey] = useState<boolean>(false);
  const [loadingProfile, setLoadingProfile] = useState<boolean>(true);
  const [loadingImage, setLoadingImage] = useState<boolean>(false); 
  const [error, setError] = useState<string | null>(null);
  const [uploadError, setUploadError] = useState<string | null>(null); 

  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      const { name, email } = JSON.parse(userData);
      setUserName(name);
      setUserEmail(email);

      axios.get(`https://mozart-api-21ea5fd801a8.herokuapp.com/api/profile/${email}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          setUserImage(response.data.image);
          setPublicKey(response.data.publicKeyXlm);
        })
        .catch(error => {
          console.error('Error fetching user information:', error);
          setError('Failed to load user data');
        })
        .finally(() => {
          setLoadingProfile(false);
        });
    }
  }, []);

  const truncateKey = (key: string | null) => {
    if (!key) return '';
    return key.length > 10 ? `${key.slice(0, 5)}...${key.slice(-5)}` : key;
  };

  const copyToClipboard = (text: string | null) => {
    if (text) {
      navigator.clipboard.writeText(text);
      alert('Key copied to clipboard!');
    }
  };

  const handleImageUpload = async (file: File) => {
    // Image upload logic...
  };

  const togglePrivateKeyBlur = async () => {
    if (isPrivateKeyBlurred) {
      if (!privateKey) {
        try {
          setLoadingPrivateKey(true);
          const response = await axios.post('https://mozart-api-21ea5fd801a8.herokuapp.com/api/xlm/decrypt', 
          {
            email: userEmail
          }, {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
              Accept: '*/*',
            },
          });

          setPrivateKey(response.data.privateKey);
        } catch (error) {
          console.error('Error decrypting private key:', error);
          setError('Failed to decrypt private key');
        } finally {
          setLoadingPrivateKey(false);
        }
      }
    }
    setIsPrivateKeyBlurred(!isPrivateKeyBlurred);
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Grid templateColumns={{ base: '1fr', lg: '1.34fr 1fr 1.62fr' }} templateRows={{ base: 'repeat(3, 1fr)', lg: '1fr' }} gap={{ base: '20px', xl: '20px' }}>
        <Banner gridArea="1 / 1 / 1 / 3" banner={banner} avatar={userImage || avatar} name={userName} job={userEmail} posts="0" followers="0" following="0" />

        <Box>
          <Text>Change your profile avatar:</Text>
          <Input type="file" accept="image/*" onChange={(e) => handleImageUpload(e.target.files![0])} />
          {loadingImage && <Text>Uploading...</Text>}
          {uploadError && <Text color="red">{uploadError}</Text>}
          <Button mt="2">Update now</Button>
        </Box>
      </Grid>

      <Grid mb="20px" templateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)', '2xl': '1.34fr 1.62fr 1fr' }} templateRows={{ base: '1fr', lg: 'repeat(2, 1fr)', '2xl': '1fr' }} gap={{ base: '20px', xl: '20px' }}>
        <Box>
          {/* Public Key Display */}
          <Text fontSize="lg" fontWeight="bold">Public Key:</Text>
          {loadingProfile ? (
            <Text>Loading...</Text>
          ) : (
            <Box display="flex" alignItems="center">
              <Text fontSize="md" wordBreak="break-all">{truncateKey(publicKey)}</Text>
              <Tooltip label="Copy Public Key" fontSize="md">
                <IconButton icon={<CopyIcon />} onClick={() => copyToClipboard(publicKey)} aria-label="Copy Public Key" size="sm" ml="2" />
              </Tooltip>
            </Box>
          )}

          {/* Private Key Display */}
          <Text fontSize="lg" fontWeight="bold" mt="4">Private Key:</Text>
          <Box display="flex" alignItems="center">
            <Text
              fontSize="md"
              wordBreak="break-all"
              opacity={isPrivateKeyBlurred ? 0.3 : 1}
              style={{
                filter: isPrivateKeyBlurred ? 'blur(5px)' : 'none',
                transition: 'all 0.3s ease-in-out',
              }}
            >
              {loadingPrivateKey ? 'Loading...' : (privateKey ? truncateKey(privateKey) : 'Hidden')}
            </Text>
            <Tooltip label={isPrivateKeyBlurred ? 'Show Private Key' : 'Copy Private Key'} fontSize="md">
              <IconButton
                icon={<CopyIcon />}
                onClick={() => {
                  if (isPrivateKeyBlurred) {
                    togglePrivateKeyBlur();
                  } else {
                    copyToClipboard(privateKey);
                  }
                }}
                aria-label="Copy Private Key"
                size="sm"
                ml="2"
                isDisabled={loadingPrivateKey}
              />
            </Tooltip>
          </Box>

          <Button mt="2" onClick={togglePrivateKeyBlur} isDisabled={loadingPrivateKey}>
            {isPrivateKeyBlurred ? 'Show Private Key' : 'Hide Private Key'}
          </Button>
        </Box>
      </Grid>

      {error && <Text color="red.500" mt="4">{error}</Text>}
    </Box>
  );
}
