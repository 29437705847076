import React, { useState } from 'react';
import {
  Box,
  Text,
  useColorModeValue,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  InputGroup,
  InputLeftElement,
  FormControl,
  FormLabel,
  VStack,
  Stack,
  useToast,
  Flex,
  Select as ChakraSelect,
} from '@chakra-ui/react';
import axios from 'axios';

interface CurrencySymbols {
  [key: string]: string;
}

const currencySymbols: CurrencySymbols = {
  USD: '$',
  EUR: '€',
  COP: '$COP',
};

export default function Settings() {
  const [amount, setAmount] = useState<number>(0);
  const [sourceCurrency, setSourceCurrency] = useState<string>('USD');
  const [targetCurrency, setTargetCurrency] = useState<string>('USD');
  const [convertedAmount, setConvertedAmount] = useState<number | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [receiverEmail, setReceiverEmail] = useState('');
  const [receiverName, setReceiverName] = useState('');
  const [isConverting, setIsConverting] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const toast = useToast();

  const userData = localStorage.getItem('user');
  const { email } = JSON.parse(userData);
  const senderEmail = email;

  const handleForwardClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSend = async () => {
    if (!receiverEmail || !receiverName) {
      toast({
        title: 'Error',
        description: 'Please provide all receiver details.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsSending(true);
    try {
      const response = await axios.post(
        'https://mozart-api-21ea5fd801a8.herokuapp.com/api/transaction',
        {
          senderEmail,
          amount,
          receiverEmail,
          receiverName,
          targetCurrency,
        }
      );

      if (response.status === 200) {
        toast({
          title: 'Success',
          description: 'Request has been sent successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        setShowModal(false);
      }
    } catch (error) {
      console.error('Error sending data:', error);
      toast({
        title: 'Error',
        description: 'There was an error sending the request.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSending(false);
    }
  };

  const handleConvert = async () => {
    if (!amount || amount <= 0) {
      toast({
        title: 'Error',
        description: 'Please enter a valid amount.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsConverting(true);
    try {
      const response = await axios.post(
        'https://mozart-api-21ea5fd801a8.herokuapp.com/api/convert',
        { amount, sourceCurrency, targetCurrency }
      );
      setConvertedAmount(response.data.convertedAmount);
      toast({
        title: 'Conversion Successful',
        description: `Converted Amount: ${response.data.convertedAmount} ${targetCurrency}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error converting:', error);
      toast({
        title: 'Error',
        description: 'There was an error converting the amount.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsConverting(false);
    }
  };

  const handleSourceCurrencySwitch = () => {
    const currencyKeys = Object.keys(currencySymbols);
    const currentIndex = currencyKeys.indexOf(sourceCurrency);
    const nextIndex = (currentIndex + 1) % currencyKeys.length;
    setSourceCurrency(currencyKeys[nextIndex]);
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Text
        mt='45px'
        mb='36px'
        color={textColor}
        fontSize='2xl'
        ms='24px'
        fontWeight='700'
      >
        Please enter the amount you want to send:
      </Text>

      <Box>
        <VStack spacing={4} align='start'>
          <FormControl>
            <FormLabel>Amount:</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents='auto'
                color='gray.300'
                fontSize='1.2em'
                cursor='pointer'
                onClick={handleSourceCurrencySwitch}
                children={currencySymbols[sourceCurrency]}
              />
              <Input
                type='number'
                placeholder='Enter amount'
                value={amount}
                onChange={(e) => setAmount(parseFloat(e.target.value))}
              />
            </InputGroup>
          </FormControl>

          <FormControl>
            <FormLabel>Target Currency:</FormLabel>
            <ChakraSelect
              value={targetCurrency}
              onChange={(e) => setTargetCurrency(e.target.value)}
            >
              {Object.keys(currencySymbols).map((currencyCode) => (
                <option key={currencyCode} value={currencyCode}>
                  {currencyCode} - {currencySymbols[currencyCode]}
                </option>
              ))}
            </ChakraSelect>
          </FormControl>

          <Button
            variant='darkBrand'
            color='white'
            fontSize='sm'
            fontWeight='500'
            borderRadius='70px'
            px='24px'
            py='5px'
            onClick={handleConvert}
            isLoading={isConverting}
            loadingText='Converting...'
            isDisabled={!amount || amount <= 0} // Disable if amount is invalid
          >
            Convert
          </Button>

          {convertedAmount !== null && (
            <Text>
              Converted Amount: {convertedAmount} {targetCurrency}
            </Text>
          )}
        </VStack>
      </Box>

      <Flex justifyContent='flex-end' mt={8}>
        <Button
          variant='darkBrand'
          color='white'
          fontSize='sm'
          fontWeight='500'
          borderRadius='70px'
          px='24px'
          py='5px'
          onClick={handleForwardClick}
          isDisabled={!convertedAmount || amount <= 0} // Disable if conversion hasn't happened
        >
          Forward
        </Button>
      </Flex>

      {/* Modal */}
      <Modal isOpen={showModal} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Forward Money</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={3}>
              <FormControl isRequired>
                <FormLabel>Receiver Name</FormLabel>
                <Input
                  placeholder='Receiver Name'
                  value={receiverName}
                  onChange={(e) => setReceiverName(e.target.value)}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Receiver Email</FormLabel>
                <Input
                  placeholder='Receiver Email'
                  value={receiverEmail}
                  onChange={(e) => setReceiverEmail(e.target.value)}
                />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              variant='darkBrand'
              onClick={handleSend}
              isLoading={isSending}
              loadingText='Sending...'
            >
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
