import { Icon } from '@chakra-ui/react';
import { MdBarChart, MdPerson, MdHome, MdAttachMoney, MdOutlineShoppingCart } from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/admin/default';
import Profile from 'views/admin/profile';
import DataTables from 'views/admin/add';
import Withdraw from 'views/admin/withdraw';
import Money from 'views/admin/money';
import Request from 'views/admin/request';
import Identity from 'views/admin/identity';
import OAs from 'views/admin/add/oas';
import Airtm from 'views/admin/airtm';
import Marketplace from 'views/admin/marketplace';
import Home from 'layouts/landing/home';

const routes = [
  {
    name: 'Main Dashboard',
    layout: '/admin',
    path: '/default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: 'Add',
    layout: '/admin',
    path: '/add',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    component: DataTables,
  },
  {
    name: 'Withdraw',
    layout: '/admin',
    path: '/withdraw',
    icon: <Icon as={MdAttachMoney} width="20px" height="20px" color="inherit" />,
    component: Withdraw,
  },
  {
    name: 'Airtm',
    layout: '/pay',
    path: '/airtm',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    component: Airtm,
  },
  {
    name: 'Profile',
    layout: '/admin',
    path: '/profile',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Profile,
  },
  {
    name: 'Home',
    layout: '/home',
    path: '/landing',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: Home,
  },
  {
    name: 'Send Money',
    layout: '/admin',
    path: '/sendMoney',
    icon: <Icon as={MdAttachMoney} width="20px" height="20px" color="inherit" />,
    component: Money,
  },
  {
    name: 'Request Money',
    layout: '/admin',
    path: '/requestMoney',
    icon: <Icon as={MdAttachMoney} width="20px" height="20px" color="inherit" />,
    component: Request,
  },
  {
    name: 'Identity Verification',
    layout: '/admin',
    path: '/identity',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Identity,
  },
  {
    name: 'OAs',
    layout: '/admin',
    path: '/oas',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    component: OAs,
  },
  {
    name: 'Gaming Marketplace',
    layout: '/admin',
    path: '/marketplace',
    icon: <Icon as={MdOutlineShoppingCart} width="20px" height="20px" color="inherit" />,
    component: Marketplace,
  },
];

export default routes;
