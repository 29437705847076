import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  SimpleGrid,
  useColorModeValue,
  Button,
  Flex,
  Image,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Spinner,
  Center,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import Card from 'components/card/Card';
import axios from 'axios';
import USDC from 'assets/img/dashboards/USDC.png';
import XLM from 'assets/img/dashboards/XLM.png';
import EURC from 'assets/img/dashboards/EURC.png'; // Add EURC image

interface StellarAccount {
  publicKey: string;
  balance: string;
  hasUSDCTrustline: boolean;
  hasEURCTrustline: boolean; // New field for EURC trustline
}

interface StellarAccountResponse {
  publicKey: string;
  balance: string;
  hasUSDCTrustline: boolean;
  hasEURCTrustline: boolean; // New field for EURC trustline
}

export default function Settings() {
  const [currency, setCurrency] = useState<string>('');
  const [stellarAccount, setStellarAccount] = useState<StellarAccount | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const toast = useToast();

  const userData = localStorage.getItem('user');
  const { email } = userData ? JSON.parse(userData) : { email: '' };

  // Utility function to update LocalStorage safely
  const updateLocalStorage = (key: string, value: any) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error('Error saving to localStorage', error);
    }
  };

  // Load the account details from localStorage when the component mounts
  useEffect(() => {
    const storedStellarAccount = localStorage.getItem('stellarAccount');
    if (storedStellarAccount) {
      setStellarAccount(JSON.parse(storedStellarAccount));
    } else {
      fetchStellarAccount(); // Fetch from API if not present in localStorage
    }
  }, []);

  // Function to log and analyze errors
  const logErrorDetails = (error: any) => {
    if (error.response) {
      console.error('Error response data:', error.response.data);
      console.error('Error status:', error.response.status);
      console.error('Error headers:', error.response.headers);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
  };

  // Fetch the Stellar account details
  const fetchStellarAccount = async () => {
    try {
      setLoading(true);
      console.log('Fetching stellar account and balance for:', email);
  
      // Call the /balance route to get the balance and public key
      const response = await axios.get<{ balance: string; publicKey: string }>(
        `https://mozart-api-21ea5fd801a8.herokuapp.com/api/balance?email=${email}`,
        { headers: { 'Content-Type': 'application/json' } }
      );
  
      const { balance, publicKey } = response.data;
  
      if (balance && publicKey) {
        const updatedAccount: StellarAccount = {
          publicKey,
          balance,
          hasUSDCTrustline: false, // Modify this based on actual trustline status
          hasEURCTrustline: false, // Add for EURC trustline
        };
  
        setStellarAccount(updatedAccount);
        updateLocalStorage('stellarAccount', updatedAccount);
      } else {
        setStellarAccount(null);
        localStorage.removeItem('stellarAccount');
      }
    } catch (error) {
      console.error('Error fetching Stellar account and balance:', error);
      logErrorDetails(error);
      setError('Failed to fetch balance and public key. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleCurrencyChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setCurrency(event.target.value);
    setError(null); // Clear previous errors
  };

  // Function to handle USDC trustline creation
  const handleUSDC = async () => {
    if (stellarAccount && currency === 'USD') {
      const response = await axios.post(
        'https://mozart-api-21ea5fd801a8.herokuapp.com/api/trustline',
        { email, currency: 'USD' },
        { headers: { 'Content-Type': 'application/json', Accept: '*/*' } }
      );

      const { hasUSDCTrustline, publicKey } = response.data;
      if (hasUSDCTrustline) {
        const updatedAccount = {
          ...stellarAccount,
          publicKey,
          hasUSDCTrustline: true,
        };
        setStellarAccount(updatedAccount);
        updateLocalStorage('stellarAccount', updatedAccount);
        setLoading(false);
        onOpen();
      }
    }
  };

  // Function to handle EURC trustline creation
  const handleEURC = async () => {
    if (stellarAccount && currency === 'EUR') {
      const response = await axios.post(
        'https://mozart-api-21ea5fd801a8.herokuapp.com/api/trustline',
        { email, currency: 'EUR' },
        { headers: { 'Content-Type': 'application/json', Accept: '*/*' } }
      );

      const { hasEURCTrustline, publicKey } = response.data;
      if (hasEURCTrustline) {
        const updatedAccount = {
          ...stellarAccount,
          publicKey,
          hasEURCTrustline: true,
        };
        setStellarAccount(updatedAccount);
        updateLocalStorage('stellarAccount', updatedAccount);
        setLoading(false);
        onOpen();
      }
    }
  };

  const handleXLM = async () => {
    if (!stellarAccount) {
      try {
        const response = await axios.post<StellarAccountResponse>(
          'https://mozart-api-21ea5fd801a8.herokuapp.com/api/xlm/',
          { email, currency: 'XLM' },
          { headers: { 'Content-Type': 'application/json', Accept: '*/*' } }
        );

        const { publicKey } = response.data;

        const balanceResponse = await axios.get(
          `https://mozart-api-21ea5fd801a8.herokuapp.com/api/balance?email=${email}`,
          { headers: { 'Content-Type': 'application/json' } }
        );

        const balance = balanceResponse.data.balance;

        const newStellarAccount = {
          publicKey,
          balance,
          hasUSDCTrustline: false,
          hasEURCTrustline: false, // Add EURC status
        };

        setStellarAccount(newStellarAccount);
        updateLocalStorage('stellarAccount', newStellarAccount);
        setLoading(false);
        onOpen();
      } catch (error) {
        console.error('Error creating XLM account or fetching balance:', error);
        logErrorDetails(error);
        setError('Failed to create XLM account or fetch balance. Please try again later.');
        setLoading(false);
      }
    }
  };

  const handleAddPayment = async () => {
    setLoading(true);
    setError(null);
    try {
      switch (currency) {
        case 'USD':
          await handleUSDC();
          break;
        case 'EUR':
          await handleEURC(); // Call the EURC handler for EUR currency
          break;
        case 'XLM':
          await handleXLM();
          break;
        default:
          console.log(`Currency ${currency} not supported yet.`);
      }
    } catch (error) {
      console.error('Error creating trustline or account:', error);
      logErrorDetails(error);
      setError('An error occurred while creating the account or trustline. Please try again later.');
      setLoading(false);
    }
  };

  const handleCopy = async (publicKey: string) => {
    try {
      await navigator.clipboard.writeText(publicKey);
      toast({
        title: 'Public key copied to clipboard!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Failed to copy public key',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const PaymentMethodCard = ({
    imgSrc,
    title,
    description,
    onClick,
    displayInfo,
  }: {
    imgSrc: string;
    title: string;
    description: string;
    onClick: () => void;
    displayInfo?: JSX.Element;
  }) => (
    <Card p="20px">
      <Flex direction={{ base: 'column' }} justify="center">
        <Box mb={{ base: '20px', '2xl': '20px' }} position="relative">
          <Image src={imgSrc} w={{ base: '100%', '3xl': '100%' }} h={{ base: '100%', '3xl': '100%' }} borderRadius="20px" />
        </Box>
        <Flex flexDirection="column" justify="space-between" h="100%">
          <Flex justify="space-between" direction={{ base: 'row', md: 'column', lg: 'row', xl: 'column', '2xl': 'row' }} mb="auto">
            <Flex direction="column">
              <Text color={textColor} fontSize={{ base: 'xl', md: 'lg', xl: 'lg', '2xl': 'md', '3xl': 'lg' }} mb="5px" fontWeight="bold">
                {title}
              </Text>
              <Text color="secondaryGray.600" fontSize={{ base: 'sm' }} fontWeight="400">
                {description}
              </Text>
            </Flex>
          </Flex>
          <Flex align="center" justify="space-between" mt="25px" wrap="wrap">
            {displayInfo || (
              <Button
                variant="darkBrand"
                color="white"
                fontSize="sm"
                fontWeight="500"
                borderRadius="70px"
                px="24px"
                py="5px"
                onClick={onClick}
                isLoading={loading} // Show loading spinner
                loadingText="Creating..."
              >
                Create Account
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );

  const maskedPublicKey = stellarAccount
    ? `${stellarAccount.publicKey.slice(0, 10)}...${stellarAccount.publicKey.slice(-10)}`
    : '';

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Text mt="45px" mb="12px" color={textColor} fontSize="2xl" ms="24px" fontWeight="700">
        Please select the currency to add:
      </Text>
      <Select placeholder="Select currency" value={currency} onChange={handleCurrencyChange} mb="12px" maxWidth="300px" ms="24px">
        <option value="USD">USD</option>
        <option value="XLM">XLM</option>
        <option value="EUR">EUR</option> {/* Add EUR option */}
      </Select>

      {error && <Text color="red.500" ms="24px" mt="12px">{error}</Text>}

      <Text mt="12px" mb="12px" color={textColor} fontSize="2xl" ms="24px" fontWeight="700">
        Please choose your payment method:
      </Text>
      <SimpleGrid mb="20px" columns={{ sm: 2, md: 3 }} spacing={{ base: '20px', xl: '20px' }} ms="24px">
        {currency === 'USD' && stellarAccount?.hasUSDCTrustline ? (
          <PaymentMethodCard
            imgSrc={USDC}
            title="USDC"
            description="You already have a USDC trustline."
            displayInfo={
              <Flex align="center">
                <Text fontWeight="bold">Public Key: {maskedPublicKey}</Text>
                <IconButton
                  aria-label="Copy public key"
                  icon={<CopyIcon />}
                  onClick={() => handleCopy(stellarAccount.publicKey)}
                  ml="10px"
                  size="sm"
                />
              </Flex>
            }
            onClick={() => {}} // No action since trustline exists
          />
        ) : currency === 'USD' ? (
          <PaymentMethodCard
            imgSrc={USDC}
            title="USDC"
            description="USDC is a digital dollar always redeemable 1:1"
            onClick={handleAddPayment}
          />
        ) : null}

        {currency === 'EUR' && stellarAccount?.hasEURCTrustline ? ( // New EURC trustline check
          <PaymentMethodCard
            imgSrc={EURC}
            title="EURC"
            description="You already have a EURC trustline."
            displayInfo={
              <Flex align="center">
                <Text fontWeight="bold">Public Key: {maskedPublicKey}</Text>
                <IconButton
                  aria-label="Copy public key"
                  icon={<CopyIcon />}
                  onClick={() => handleCopy(stellarAccount.publicKey)}
                  ml="10px"
                  size="sm"
                />
              </Flex>
            }
            onClick={() => {}} // No action since trustline exists
          />
        ) : currency === 'EUR' ? (
          <PaymentMethodCard
            imgSrc={EURC}
            title="EURC"
            description="EURC is a digital euro always redeemable 1:1"
            onClick={handleAddPayment}
          />
        ) : null}

        {currency === 'XLM' && stellarAccount ? (
          <PaymentMethodCard
            imgSrc={XLM}
            title="XLM"
            description="Your Stellar account is already created."
            displayInfo={
              <Flex align="center">
                <Text fontWeight="bold">Public Key: {maskedPublicKey}</Text>
                <IconButton
                  aria-label="Copy public key"
                  icon={<CopyIcon />}
                  onClick={() => handleCopy(stellarAccount.publicKey)}
                  ml="10px"
                  size="sm"
                />
              </Flex>
            }
            onClick={() => {}} // No action since account exists
          />
        ) : currency === 'XLM' ? (
          <PaymentMethodCard
            imgSrc={XLM}
            title="XLM"
            description="Stellar payment method"
            onClick={handleAddPayment}
          />
        ) : null}
      </SimpleGrid>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Stellar Account Creation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading ? (
              <Center>
                <Spinner size="xl" />
              </Center>
            ) : (
              <>
                <Text>Your account has been successfully created. You can now receive payments at this address:</Text>
                {stellarAccount && (
                  <Flex align="center">
                    <Text fontWeight="bold" mt="2">Public Key: {maskedPublicKey}</Text>
                    <IconButton
                      aria-label="Copy public key"
                      icon={<CopyIcon />}
                      onClick={() => handleCopy(stellarAccount.publicKey)}
                      ml="10px"
                      size="sm"
                    />
                  </Flex>
                )}
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

