import React from 'react';
import ReactDOM from 'react-dom/client';  
import './assets/css/App.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import SignUp from './views/auth/signUp';
import SignIn from './views/auth/signIn';
import Default from './views/admin/default';
import Password from './views/auth/password/password';
import Verify from './views/auth/password/verification';
import ResetPassword from './views/auth/password/reset';
import AdminLayout from './layouts/admin';
import Home from './layouts/landing/home';
import Terms from './layouts/terms/home';
import Privacy from './layouts/Privacy/home';
import Contact from './layouts/contact/home';
import Imprint from './layouts/imprint/home';
import Docs from './layouts/docs/home';
import Blogs from './layouts/blog/home';
import RTLLayout from './layouts/rtl';
import SuccessPage from './layouts/transaction/confirm';
import RejectPage from './layouts/transaction/rejected';
import FailurePage from './layouts/transaction/failed';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import Marketplace from 'views/admin/marketplace';
import IntroMozartPay from 'layouts/introMozartBlog/hero';
import OasBlog from "./layouts/oasBlog/home";


const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/auth/*" element={<AuthLayout />} />
          <Route path="/admin/*" element={<AdminLayout />} />
       <Route path="/admin/defaultt" element={<Default />} />
          <Route path="/marketplace" element={<Marketplace />} />
          <Route path="/rtl" element={<RTLLayout />} />
          <Route path="/home" element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/imprint" element={<Imprint />} />
          <Route path="/docs" element={<Docs />} />
          <Route path="/contactUs" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/introducing-mozartpay" element={<IntroMozartPay />} />
          <Route path="/blogs/orchestrated-agreements" element={<OasBlog />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/confirm" element={<SuccessPage />} />
          <Route path="/cancel" element={<RejectPage />} />
          <Route path="/callback" element={<FailurePage />} />
          <Route path="/forgot_password" element={<Password />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/verifyAccount" element={<Verify />} />
          <Route path="*" element={<Navigate to="/home" replace />} />
          
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </ChakraProvider>
);
