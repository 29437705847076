import {
  Box,
  SimpleGrid,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { MdAttachMoney } from 'react-icons/md';

type BalanceObj = {
  asset_code: string;
  balance: string;
};

type Transaction = {
  id: string;
  created_at: string;
  amount: string;
  asset_type: string;
  to: string;
  from: string;
};

export default function UserReports() {
  const userData = localStorage.getItem('user');
  const { email } = JSON.parse(userData || '{}');
  const [balances, setBalances] = useState<BalanceObj[]>([]); // Store multiple asset balances
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [error, setError] = useState<string | null>(null); // Add error state

  useEffect(() => {
    if (email) {
      setIsLoading(true);
      setError(null);
      axios({
        method: 'get',
        url: `https://mozart-api-21ea5fd801a8.herokuapp.com/api/balance?email=${encodeURIComponent(email)}`,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          const { balances } = response.data;
          setBalances(balances || []); // Ensure balances exist or set to empty array
        })
        .catch((error) => {
          console.error('Error fetching balances:', error);
          setError('Failed to load balances.');
        })
        .finally(() => {
          setIsLoading(false); // Always stop loading after fetch
        });
    }
  }, [email]);

  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

  return (
    <Box pt={{ base: '130px', md: '100px', xl: '80px' }}>
      <SimpleGrid columns={{ base: 1, md: 3, lg: 3, '2xl': 6 }} gap="20px" mb="20px">
        {balances.map((balance, index) => (
          <MiniStatistics
            key={index}
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={<Icon w="32px" h="32px" as={MdAttachMoney} color={brandColor} />}
              />
            }
            name={`Balance (${balance.asset_code})`}
            value={`${balance.asset_code} ${balance.balance}`} // Display each asset balance
          />
        ))}
      </SimpleGrid>

      {/* Display loading state or error message */}
      {isLoading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <SimpleGrid>
          {/* You can add more content here like transactions */}
        </SimpleGrid>
      )}
    </Box>
  );
}
