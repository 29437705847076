import { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import routes from 'routes';

// Chakra imports
import { Box, useColorModeValue } from '@chakra-ui/react';

// Layout components
import { SidebarContext } from 'contexts/SidebarContext';

// Custom Chakra theme
export default function Auth() {
  // States and functions
  const [toggleSidebar, setToggleSidebar] = useState(false); 
  
  const getRoute = () => window.location.pathname !== '/auth/full-screen-maps';

  const getRoutes = (routes: RoutesType[]): JSX.Element[] => {
    return routes.map((route: RoutesType, key: number) => {
      if (route.layout === '/auth') {
        return <Route path={route.layout + route.path} element={<route.component />} key={key} />;
      }
      return null;
    });
  };

  const authBg = useColorModeValue('white', 'navy.900');
  document.documentElement.dir = 'ltr';

  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar
        }}>
        <Box
          bg={authBg}
          float='right'
          minHeight='100vh'
          height='100%'
          position='relative'
          w='100%'
          transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
          transitionDuration='.2s, .2s, .35s'
          transitionProperty='top, bottom, width'
          transitionTimingFunction='linear, linear, ease'>
          {getRoute() ? (
            <Box mx='auto' minH='100vh'>
              <Routes>
                {getRoutes(routes)}
                <Route path="*" element={<Navigate to='/signin' replace />} /> {/* Fixed this line */}
              </Routes>
            </Box>
          ) : null}
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
